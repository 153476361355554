import { defineStore } from 'pinia';

export const useUiStore = defineStore('ui', {
  state: () => ({
    loading: false,
    shouldCollapseNav: false,
  }),

  actions: {
    setLoading(value) {
      this.loading = value;
    },

    setShouldCollapseNav(value) {
      this.shouldCollapseNav = value;
    },
  },
});
