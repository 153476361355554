<template>
  <transition
    leave-active-class="fade-leave-transition"
    leave-to-class="fade-out"
  >
    <div
      v-if="!cookie"
      id="cookieNotice"
      :class="[
        'tw-fixed tw-bottom-0 tw-left-0 tw-z-30',
        'tw-w-full tw-py-3 tw-px-5',
        'tw-flex tw-justify-between tw-items-center',
        'tw-border-t',
        'card-background-color border-color',
      ]"
    >
      <p class="tw-text-sm">
        We use cookies to better understand website behavior and share relevant information. Learn
        more in our
        <NuxtLink
          class="tw-font-bold link-color-brand"
          to="/privacy"
        >
          Privacy Policy.
        </NuxtLink>
      </p>
      <Button
        :class="[
          'tw-text-sm',
          'tw-py-1 tw-px-2',
          'tw-leading-normal tw-whitespace-nowrap',
        ]"
        :type="ButtonTypes.primary"
        @click="handleAcceptCookiesClick"
      >
        Got it!
      </Button>
    </div>
  </transition>
</template>

<script setup>
import { ButtonTypes } from '../Common/Button.vue';
import { useCookieWrapper } from '../../composables/useCookieWrapper';

const cookie = useCookieWrapper('cookienotice');
const handleAcceptCookiesClick = () => cookie.value = true;
</script>
