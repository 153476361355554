<script setup>
import * as Sentry from '@sentry/vue';
import { ButtonTypes } from '@@/components/Common/Button.vue';

const props = defineProps({
  error: {
    type: Object,
    required: true,
  },
});

const config = useRuntimeConfig().public;
const is404 = props.error && props.error.statusCode === 404;
const title = `${is404 ? 'Page Not Found' : 'Something Broke'} | ${config.siteName}`;
const details = is404
  ? "Sorry, we couldn't find that page."
  : 'We are having an issue loading this page. Try again later!';

useSeoMeta({ title });

Sentry.captureException(props.error);

onMounted(() => {
  /* eslint no-console: off */
  console.error(`${config.siteName} Error: message = ${props.error.message}, statusCode = ${props.error.statusCode}`);
  console.error(props.error);
});

const handleGoBack = () => {
  clearError();
  window.history.go(-1);
};
</script>

<template>
  <div class="tw-relative tw-my-6 tw-mx-auto tw-overflow-hidden tw-max-w-4xl">
    <img
      alt="Error"
      class="tw-w-full tw-h-auto tw-rounded-md"
      src="~/assets/img/OpenSnowError.jpg"
    >
    <div class="tw-absolute tw-inset-10">
      <h2 class="tw-text-white tw-text-3xl lg:tw-text-5xl tw-font-bold tw-mb-2.5">
        Yard Sale!
      </h2>
      <h3 class="tw-text-white tw-text-2xl lg:tw-text-3xl tw-font-normal tw-mb-4 lg:tw-mb-8">
        {{ details }}
      </h3>
      <p>
        <Button
          class="tw-text-lg"
          :type="ButtonTypes.secondary"
          @click="handleGoBack"
        >
          Return
        </Button>
      </p>
    </div>
  </div>
</template>
