<template>
  <div
    v-click-away="handleClickAway"
    :class="dropdownClass"
  >
    <a
      v-if="showAnchor"
      class="link-color-brand"
      href="javascript:void(0)"
      @click="toggleMenu"
    >
      <slot name="anchor" />
    </a>
    <Button
      v-if="showButton"
      ref="button"
      :active="isMenuOpen || isActive"
      :can-show-chip-active-icon="false"
      :type="buttonType"
      @click="toggleMenu"
    >
      <slot name="button" />
    </Button>
    <transition
      enter-from-class="fade-out"
      :enter-active-class="$style.backgroundEnterTransition"
      leave-active-class="fade-leave-transition"
      leave-to-class="fade-out"
    >
      <div
        v-if="isMenuOpen && showBackgroundBlur"
        id="dropdownMenuBackgroundBlur"
        :class="backgroundClassNames"
        @click="closeMenu"
      />
    </transition>
    <Card
      :class="menuClass"
      v-bind="cardProps"
    >
      <template #body>
        <slot name="menu" />
      </template>
    </Card>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue3-click-away';
import { ButtonTypes } from '@@/components/Common/Button.vue';

export default {
  name: 'DropdownMenu',

  mixins: [clickaway],

  props: {
    additionalDropdownClass: {
      type: String,
      default: '',
    },
    buttonType: {
      type: String,
      default: ButtonTypes.secondary,
    },
    cardProps: {
      type: Object,
      default() {
        return {
          hasBodyPaddingSmall: true,
        };
      },
    },
    closeOnClickAway: {
      type: Boolean,
      default: true,
    },
    isActive: {
      type: Boolean,
      default: undefined,
    },
    isMenuFullWidth: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'left',
    },
    showBackgroundBlur: {
      type: Boolean,
      default: false,
    },
    showMenu: {
      type: Boolean,
      default: false,
    },
    zIndex: {
      type: String,
      default: 'tw-z-20',
    },
  },

  emits: ['close', 'open'],

  data() {
    return {
      isMenuOpen: false,
    };
  },

  computed: {
    backgroundClassNames() {
      return `tw-fixed tw-inset-0 tw-z-20 ${this.$style.background}`;
    },

    dropdownClass() {
      return `tw-relative ${this.zIndex} tw-overflow-visible ${this.additionalDropdownClass}`;
    },

    showAnchor() {
      return !!this.$slots.anchor;
    },

    showButton() {
      return !!this.$slots.button;
    },

    menuClass() {
      let position = 'tw-left-0';

      if (this.position === 'right') {
        position = 'tw-right-0';
      }
      else if (this.position && this.position !== 'right' && this.position !== 'left') {
        // Allow parent to specify custom position
        position = this.position;
      }

      return [
        'tw-absolute tw-top-full',
        this.zIndex,
        position,
        this.isMenuFullWidth ? 'tw-w-full' : null,
        'tw-mt-1',
        this.isMenuOpen ? this.$style.showMenu : this.$style.hideMenu,
        this.$style.slideTransition,
        this.showBackgroundBlur && this.isMenuOpen ? 'tw-delay-300' : null,
      ];
    },
  },

  watch: {
    isMenuOpen(newValue) {
      if (newValue) {
        this.$emit('open');
      }
      else {
        this.$emit('close');
      }

      if (this.showButton) {
        this.$refs.button.$el.blur();
      }
    },

    showMenu(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.isMenuOpen = true;
      }
      else if (!newValue && oldValue) {
        this.isMenuOpen = false;
      }
    },
  },

  methods: {
    closeMenu() {
      this.isMenuOpen = false;
    },

    handleClickAway() {
      if (this.closeOnClickAway) {
        this.closeMenu();
      }
    },

    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>

<style module>
.backgroundEnterTransition {
  transition: opacity var(--fade-transition-time) ease-in-out;
}

.background {
  backdrop-filter: blur(1px);
  background-color: rgb(0 0 0 / 50%);
}

.hideMenu {
  max-height: 0;
  min-height: 0;
  opacity: 0;
}

.slideTransition {
  overflow: hidden;
  transition-duration: 250ms;
  transition-property: min-height, opacity;
  transition-timing-function: ease-in-out;
}

.showMenu {
  max-height: none;
  min-height: var(--dropdown-menu-show-menu-min-height, 20rem);
  opacity: 1;
}
</style>
