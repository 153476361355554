import { bannerTypes } from '@@/components/Common/Banner.vue';

export const addReturnTo = (relativeUrl, query) => {
  if (!query?.return_to) {
    return relativeUrl;
  }

  const [path, queryString = ''] = String(relativeUrl).split('?');
  const searchParams = new URLSearchParams(queryString);
  searchParams.set('return_to', query.return_to);

  return `${path}?${searchParams.toString()}`;
};

export const navigateReturnTo = ($router, returnTo) => {
  const isNuxtRoute = $router.resolve(returnTo).matched.length;

  if (isNuxtRoute) {
    $router.push(returnTo);
  }
  else {
    window.location.href = returnTo;
  }
};

export const getLoginError = (loginStore) => {
  const { loginError } = loginStore;
  loginStore.setLoginError(null);
  return loginError;
};

export const getPromoCodeBannerType = (promoCode) => (promoCode?.is_active === false
  ? bannerTypes.error
  : bannerTypes.membership);

export const getStartFreeTrialUrl = (params = {}) => {
  const {
    isGuest = true,
    query = {},
  } = params;

  const url = isGuest ? '/user/register' : '/user/settings/start-trial';
  const search = new URLSearchParams(query).toString();

  return search ? `${url}?${search}` : url;
};
