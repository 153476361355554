<template>
  <section
    :class="{ 'tw-h-full': isFullHeight }"
  >
    <header
      v-if="hasHeaderSlot"
      :class="headerClassNames"
    >
      <slot name="header" />
    </header>
    <div
      :class="[
        $style.body, {
          'tw-h-full': isFullHeight,
          'tw-p-6': hasBodyPadding,
          'tw-p-2.5': hasBodyPaddingSmall,
          'tw-shadow': hasBodyShadow,
          [$style.noBodyStyles]: noBodyStyles,
          [$style.relativeFullHeight]: isRelativeFullHeight,
        },
        'tw-relative',
        cardBackgroundColor,
        cardClassNames,
      ]"
    >
      <CardCloseButton
        v-if="showCloseButton"
        :button-class="cardCloseButtonClass"
        class="tw-z-10"
        :has-body-padding-small="hasBodyPaddingSmall"
        @click="$emit('close-button-click')"
      />
      <slot name="body" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'Card',

  props: {
    cardBackgroundColor: {
      type: String,
      default: 'card-background-color',
    },
    cardClassNames: {
      type: String,
      default: null,
    },
    cardCloseButtonClass: {
      type: String,
      default: undefined,
    },
    hasBodyPadding: {
      type: Boolean,
      default: false,
    },
    hasBodyPaddingSmall: {
      type: Boolean,
      default: false,
    },
    hasBodyShadow: {
      type: Boolean,
      default: false,
    },
    headerClassNames: {
      type: String,
      default: 'tw-flex tw-justify-flex-start tw-px-2 tw-py-4',
    },
    isFullHeight: {
      type: Boolean,
      default: false,
    },
    isRelativeFullHeight: {
      type: Boolean,
      default: false,
    },
    noBodyStyles: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close-button-click'],

  computed: {
    hasHeaderSlot() {
      return this.$slots.header;
    },
  },
};
</script>

<style module>
.body {
  border: 1px solid var(--card-border);
  border-radius: 10px;
  overflow: hidden;
}

.noBodyStyles {
  background-color: transparent;
  border: 0 none;
  border-radius: unset;
  overflow: auto;
}

.relativeFullHeight {
  height: calc(100% - 3.25rem);
}
</style>
