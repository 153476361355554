import validate from "/buddy/pipeline-fs/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45check_45user_45global from "/buddy/pipeline-fs/middleware/01.check-user.global.js";
import _02_45save_45current_45page_45global from "/buddy/pipeline-fs/middleware/02.save-current-page.global.js";
import manifest_45route_45rule from "/buddy/pipeline-fs/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45check_45user_45global,
  _02_45save_45current_45page_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}